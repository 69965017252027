<template>
    <div>
        <b-button
            @click="$root.copyToClipboard(JSON.stringify(inputVal.customized_product_data))"
            v-if="inputVal.customized_product_data"
            size="sm"
            variant="info"
            class="mr-2"
        >
            <font-awesome-icon icon="copy"/>
        </b-button>
        <b-button size="sm"
                  v-if="inputVal.customized_product_data"
                  @click="$root.$children[0].openModal('json-modal', {data: inputVal.customized_product_data})"
                  variant="info"
        >
            <font-awesome-icon icon="eye"/>
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'CustomizedProductDataColumn',
    props: {
        value: [Object],
        savePosition: [Function],
        readOnly: [Boolean],
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>